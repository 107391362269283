import React, {useCallback, useEffect, useRef, useState} from "react";

const smoothPlay = (vid, canvas) => {
    if(canvas) {
        canvas.width = vid.videoWidth;
        canvas.height = vid.videoHeight;
        canvas.getContext('2d').drawImage(vid, 0, 0, canvas.width, canvas.height);
    }
    vid.load();
    vid.play();
}

const GameVideo = ({onVideoEnd, name, playerRef}) => {
    const videoRef = useRef(null)
    const backgroundRef = useRef(null)

    useEffect(() => {
        if(videoRef.current) {
            videoRef.current.addEventListener('ended', onVideoEnd, false);
            return () => videoRef.current.removeEventListener('ended', onVideoEnd)
        }
    }, [videoRef, onVideoEnd])

    // Note: Safari blocks .play calls outside from click handlers
    // useEffect(() => {
    //     if(videoRef.current && autoplay) {
    //         smoothPlay(videoRef.current, backgroundRef.current)
    //     }
    // }, [videoRef, name])

    useEffect(() => {
        playerRef.current = (forcedName) => {
            if(videoRef.current) {
                console.log(forcedName)
                for(let source of videoRef.current.children) {
                    source.src = forcedName;
                }
                smoothPlay(videoRef.current, backgroundRef.current)
            }
        }
    }, [])

    return <div className="game-video__video_container">
        <canvas ref={backgroundRef}/>
        <video ref={videoRef} playsInline>
            <source src={name} type="video/mp4"/>
            <source src={name} type="video/webm"/>
            <source src={name} type="video/ogg"/>
        </video>
    </div>;
}
export const GameStage = ({videoName, answers, onAnswerSelected, firstMessage, gameOver}) => {
    const [hasStarted, setHasStarted] = useState(!firstMessage);
    useEffect(() => void setHasStarted(!firstMessage), [firstMessage]);

    const [isVideoEnded, setIsVideoEnded] = useState(false);
    useEffect(() => void setIsVideoEnded(false), [videoName]);

    const lastClosingAnswer = Boolean(answers[0] && answers[0].endgame)

    const onVideoEnd = useCallback(() => {
        setIsVideoEnded(true)
        // Note: Safari doesn't allow automatic video changes unless permission is given, so we can't skip having at least one click to play
        if(answers === null || answers.length === 0) {
            playerRef.current(gameOver())
        }

        if(lastClosingAnswer) {
            onAnswerSelected(answers[0])
        }
    }, [answers, lastClosingAnswer])

    const playerRef = useRef(() => console.log('not initialized'))

    const showAnswers = isVideoEnded || lastClosingAnswer

    return <>
        <GameVideo name={videoName} onVideoEnd={onVideoEnd} playerRef={playerRef}/>
        <div className="game-video__inputs__sticky">
            {!hasStarted &&
                <button className="game-video__answer" onClick={() => setHasStarted(true) || playerRef.current(videoName)}>{firstMessage}</button>}

            {showAnswers &&
                answers.map((answer, i) => <button className="game-video__answer" onClick={() => playerRef.current(onAnswerSelected(answer)) || setIsVideoEnded(false)} key={i}>{answer.title}</button>)}
        </div>
    </>
}
