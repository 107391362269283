function shuffleArray(d) {
    for (let c = d.length - 1; c > 0; c--) {
        let b = Math.floor(Math.random() * (c + 1));
        let a = d[c];
        d[c] = d[b];
        d[b] = a;
    }
    return d
}

export const sceneCtx = (videoPrefix, videoCode, right, wrong, firstMessage) => {
    const newScene = (videoCode, right, wrong) => ({
        videoName: require(`../../assets/media/videos/${videoPrefix}${videoCode}.mp4`),
        answers: right ? shuffleArray([{title: right, success: true}, {title: wrong}]) : [],
        endgame: right === undefined
    })

    const sceneSplit = (videoCodes, right, wrong) => videoCodes.map(code => newScene(code, right, wrong))

    const root = newScene(videoCode, right, wrong)
    root.firstMessage = firstMessage
    let currScenes = [root];
    let nextAlternatives = []
    const ctx = {
        sceneSplit: (videoCodes, right, wrong) => {
            const [success, fail] = sceneSplit(videoCodes, right, wrong)
            currScenes.forEach(scene => {
                scene.success = success
                scene.fail = fail
            })
            currScenes = [success, fail, ...nextAlternatives]
            nextAlternatives = []
        },
        alternative: (sceneParams, condition) => {
            const alternativeScene = newScene(sceneParams.videoName, sceneParams.right, sceneParams.wrong);
            // fixme: alternativeScene.autofail = sceneParams.autofail
            if(sceneParams.autofail) {
                alternativeScene.answers = [{title: 'Результат', success: false, endgame: true}]
            }
            nextAlternatives.push(alternativeScene)
            currScenes.forEach(scene => {
                scene.alternative = alternativeScene
                scene.condition = condition
            })
        },
        root
    }
    ctx.end = (videoCodes) => {
        ctx.ending = sceneSplit(videoCodes).map((scene,i) => {
            scene.answers = [{title: 'Результат', success: i === 0, endgame: true}]
            return scene
        })
    }
    ctx.build = () => ({
        root: ctx.root, ending: ctx.ending
    })

    return ctx
}
