import {sceneCtx} from "./ctx";

const resultCounter = (acc, result) => acc + (result ? 1 : -1)

export const gameGirl = (() => {
    const ctx = sceneCtx('game_w/w', '1', 'Воу, погоди!', 'Конечно! Диктуй адрес.', 'Привет!')
    ctx.sceneSplit(['2','3'], 'Что делает шляпа? ', 'А может все-таки...')

    ctx.alternative({
        videoName: '5e', autofail: true
    }, (combo) => !combo[0] && !combo[1])
    ctx.sceneSplit(['4','5'], 'Как я, только Меркурий?', 'Да, обожаю астрологию, а еще...')

    ctx.sceneSplit(['6','7'], 'У меня огромный питон', 'У меня котик')
    ctx.sceneSplit(['8','9'], 'Пересказывать сериал', 'Обещать новые туфли')

    ctx.alternative({
        videoName: '11e'
    }, (combo) => !combo[4] && combo.reduce(resultCounter,0) <= -3)
    ctx.sceneSplit(['10','11'], 'Крабы. Крабы лучшие.', 'Раки. Идеально к пиву.')

    ctx.alternative({
        videoName: '13e',
        right: 'Выдумываешь. Это скорее название порно.',
        wrong: 'Люблю этот фильм. С самого детства.'
    }, (combo) => !combo[0] && !combo[5])
    ctx.sceneSplit(['12','13'], 'Выдумываешь. Это скорее название порно.', 'Люблю этот фильм. С самого детства.')


    ctx.alternative({
        videoName: '15e',
        right: 'Ты все усложняешь',
        wrong: 'Ты очень разумная'
    }, (combo) => combo[4] && !combo[5] && combo[6])
    ctx.sceneSplit(['14','15'], 'Ты все усложняешь', 'Ты очень разумная')

    ctx.alternative({
        videoName: '17e',
        right: 'Буду. Не вижу ничего страшного. ',
        wrong: 'Не буду. Такое себе.'
    }, (combo) => !combo[2] && !combo[7])
    ctx.sceneSplit(['16','17'], 'Буду. Не вижу ничего страшного. ', 'Не буду. Такое себе.')

    ctx.alternative({
        videoName: '19e'
    }, (combo) => !combo[8] && combo.reduce(resultCounter,0) === 0)
    ctx.sceneSplit(['18','19'], 'Начнем с чипсов', 'Диктуй адрес')
    ctx.alternative({
        videoName: '21e'
    }, (combo) => !combo[0] && !combo[9] && combo.reduce(resultCounter,0) > 1)
    ctx.sceneSplit(['20','21'])
    ctx.end(['22','23'])
    return ctx.build()
})();
