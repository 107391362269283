import React, {useCallback, useLayoutEffect, useRef, useState} from "react";

// imported from the old codebase
class PolygonRender {
    constructor(el, sizes) {
        Object.assign(this, {
            UI: {
                el
            },
            on: {
                resize: () => {
                        let canvas;
                        [].forEach.call(el.children, (el) => {
                            if (el.tagName === 'CANVAS') {
                                canvas = el;
                            }
                        });
                        const fillStyle = window.getComputedStyle(el).color || '#FFF';
                        const [width, height] = [el.clientWidth, el.clientHeight];
                        const [point1, point2, point3, point4] = sizes;
                        const [xy1, xy2, xy3, xy4] =
                            [
                                [width * point1[0], height * point1[1]],
                                [width * point2[0], height * point2[1]],
                                [width * point3[0], height * point3[1]],
                                [width * point4[0], height * point4[1]],
                            ];

                        Object.assign(canvas, {
                            width,
                            height,
                        });

                        const ctx = canvas.getContext('2d');
                        ctx.clearRect(0, 0, width, height);
                        ctx.lineTo(...xy1);
                        ctx.lineTo(...xy2);
                        ctx.lineTo(...xy3);
                        ctx.lineTo(...xy4);
                        ctx.lineTo(...xy1);
                        ctx.fillStyle = fillStyle;
                        ctx.fill();
                    }
            }
        });

        this.create();

        window.addEventListener('resize', this.on.resize);
        this.on.resize();
    }

    create() {
        const {el} = this.UI;
        let canvasIsExist = false;
        [].forEach.call(el.children, (el) => {
            if (el.tagName === 'CANVAS') {
                canvasIsExist = true;
            }
        });
        if (canvasIsExist) { return; }

        const canvas = document.createElement('canvas');

        Object.assign(canvas.style, {
            position: 'absolute',
            top: '0',
            left: '0',
            zIndex: -1,
        });

        el.appendChild(canvas);
    }

    destroy() {
        window.removeEventListener('resize', this.on.resize);
    }
}

export function useLegacyPolygonRenderer(polygon) {
    const [renderer, setRenderer] = useState(null)
    const polygonRef = useCallback(ref => {
            if(!ref) {
                if(renderer) {
                    renderer.destroy();
                    setRenderer(null);
                }
                return
            }

            setRenderer(new PolygonRender(ref, polygon))
    },[])

    return polygonRef
}