import {Helmet} from "react-helmet";
import React from "react";
import Footer from "../components/Footer";
import GameVideoChat from "../dynamic/GameVideoChat";

import 'assets/stylesheets/application.scss';
import SEO from "../components/seo";

const GamePage = () => {
    // const isSSR = typeof window === "undefined";
    // if(isSSR) return <div></div>
    return (
        <>
            <SEO/>
            <GameVideoChat/>
            <Footer/>
        </>
    );
};

export default GamePage;
