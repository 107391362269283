import React, {useEffect, useRef, useState} from "react";
import ChoiceGirl from "../assets/i/game-video-chat/choice-girl-anim.png";
import ChoiceBoy from "../assets/i/game-video-chat/choice-boy-anim.png";
import ChoiceConfirm from "../assets/i/game-video-chat/choice-confirm.png";
import {throttle} from "throttle-debounce";
import {useParallax} from "../utils/Parallax";

export const GameStageSelector = ({onStageSelect}) => {

    const mousemoveAreaRef = useRef(null)
    const [selected, setSelected] = useState(null)

    const parallaxRef1 = useRef(null)
    const parallaxRef2 = useRef(null)
    const parallaxRef = [[parallaxRef1,0.09],[parallaxRef2,0.09]]



    useParallax(mousemoveAreaRef,parallaxRef)


    return <>
        <h1 className="game-video__title">Выбери свидание</h1>

        <div className="game-video__selectors" ref={mousemoveAreaRef}>

            <div
                className={`game-video__girl_back ${selected === 0 ? 'game-video__girl_back__active' : `${selected === 1 ? 'game-video__girl_back__passive' : ''}`}`}
                 ref={parallaxRef1}/>
            <div
                className={`game-video__boy_back ${selected === 1 ? 'game-video__boy_back__active' : `${selected === 0 ? 'game-video__boy_back__passive' : ''}`}`}
                 ref={parallaxRef2}/>




            <div onClick = {() => setSelected(0)}
                 className={`game-video__selector game-video__selector__girl ${selected === 0 ? 'game-video__selector__active' : ''}`}>
                <img src={ChoiceGirl} alt="C девушкой"/>
                <span>С девушкой</span>
            </div>
            <div onClick = {() => setSelected(1)}
                 className={`game-video__selector game-video__selector__boy ${selected === 1 ? 'game-video__selector__active' : ''}`}>
                <span>С парнем</span>
                <img src={ChoiceBoy} alt="С парнем"/>
            </div>

        </div>
        <div className="game-video__inputs">
            <img className={`game-video__selector_accept ${selected === null ? 'game-video__selector_accept__disabled' : ''}`}
                onClick={() => onStageSelect(selected)}
                 src={ChoiceConfirm}
                 alt="Выбрать"/>
        </div>
    </>
}
