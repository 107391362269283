import {GameStageSelector} from "./GameSelector";
import {GameStage} from "./GameVideo";
import React, {useCallback, useEffect, useState} from "react";
import {GameOver} from "./GameOver";
import {games} from "./scenarios";
import {safeym} from "../utils/safeym";

const STAGE_SELECT = {
    type: 'STAGE_SELECT'
}
const STAGE_VIDEO = {
    type: 'SELECT_STAGE',
    videoName: '',
    answers: []
}
const STAGE_END = {
    type: 'STAGE_END',
    success: false,
}
const TRANSITION_START = {
    type: 'START',
    path: -1
}
const TRANSITION_ANSWER = {
    type: 'ANSWER',
    success: false,
    endgame: false,
    combo: '',
}
const TRANSITION_GAME_OVER = {
    type: 'GAME_OVER',
}
const resultCounter = (acc, result) => acc + (result ? 1 : -1)

function useGameState() {
    const [currentStage, setCurrentStage] = useState(STAGE_SELECT);

    const [currentScene, setCurrentScene] = useState(null)
    const [comboStack, setComboStack] = useState([])
    const [ending, setEnding] = useState(null)

    const putTransition = useCallback((transition) => {
        switch (transition.type) {
            case TRANSITION_START.type:
                safeym('reachGoal','choose')
                const chosenGame = transition.path === 0 ? 'game-video-girl' : 'game-video-boy'
                safeym('reachGoal', chosenGame)

                setComboStack([])
                const selectedScene = games[transition.path].root
                setCurrentScene(selectedScene)
                setEnding(games[transition.path].ending)
                break;
            case TRANSITION_ANSWER.type:
                const newCombo = [...comboStack, transition.success]

                const runAlternative = currentScene.condition && currentScene.condition(newCombo)

                let nextScene;
                if(runAlternative)
                    nextScene = currentScene.alternative;
                else if(transition.success) {
                    safeym('reachGoal', 'right-answer')
                    nextScene = currentScene.success;
                }
                else {
                    safeym('reachGoal', 'wrong-answer')
                    nextScene = currentScene.fail;
                }

                if(transition.endgame || !nextScene) {
                    setCurrentStage({...STAGE_END, success: transition.success})
                    break;
                }
                setComboStack(newCombo)
                setCurrentScene(nextScene)
                return nextScene.videoName; // Note: Safari blocks .play calls outside from click handlers
            case TRANSITION_GAME_OVER.type:
                const alternativeFail = currentScene.autofail !== undefined ? currentScene.autofail : false
                let success = comboStack.reduce(resultCounter, 0) >= 3;
                if(alternativeFail)
                    success = false
                const endScene = ending[success ? 0 : 1]
                setCurrentScene(endScene)
                return endScene.videoName
            default:
                break;
        }
    }, [currentStage, setCurrentStage]);

    useEffect(() => {
        currentScene && setCurrentStage({...STAGE_VIDEO, answers: [], ...currentScene})
    }, [currentScene])

    return [currentStage, putTransition];
}

export const GameStates = () => {
    const [currentStage, putTransition] = useGameState()

    const startGame = (stage) => putTransition({...TRANSITION_START, path: [stage]});
    const selectAnswer = (answer) => putTransition({...TRANSITION_ANSWER, ...answer});
    const gameOver = () => putTransition({...TRANSITION_GAME_OVER});

    switch (currentStage.type) {
        case STAGE_SELECT.type:
            return <GameStageSelector onStageSelect={startGame}/>
        case STAGE_VIDEO.type:
            return <GameStage
                firstMessage={currentStage.firstMessage}
                videoName={currentStage.videoName}
                answers={currentStage.answers}
                onAnswerSelected={selectAnswer}
                gameOver={gameOver}/>
        case STAGE_END.type:
            return <GameOver success={currentStage.success}/>
        default:
            return null;
    }
}
