import "./_style.scss";
import "./_dialog_style.scss";

import React, {useRef, useState} from "react";
import {useRequest} from "../../../utils/use-request";
import Modal from "react-modal";
import {useLegacyPolygonRenderer} from "./polygon-renderer";

const AuthBtn = () => <svg viewBox="0 0 589.91 101" preserveAspectRatio="xMidYMid meet"><path d="M564.56-.011L38.7 3.723c-14.95-.356-23.96 3.178-24.56 11.285L4.132 65.371l2.632-1.811-.14 1.871-2.586 1.188-4.03 20.519c-.327 4.365 3.179 7.572 7.977 10.185s12.185 4.038 19.705 3.592l125.18-4.519 399.99-6.727c14.96.356 22.76-3.832 24.52-10.662l10.05-50.987-1.24-.03c-1.2-.653-3.7-.712-4.94-.742l.46-6.235 6.42-2.347 1.25.03.32-4.365C591.51 6.877 579.51.344 564.56-.011zm-51.48 87.484l-13.71-.326.14-1.871 13.71.326z"></path></svg>

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding: '0',
    backgroundColor: 'transparent',
    border: 'unset'
  }
};

const Dialog = ({children, type, onClose, ...props}) => {
  const polygonRef = useLegacyPolygonRenderer([[0.02,0.02],[0.98,0],[1,0.98],[0,1]])
  return <Modal closeTimeoutMS={500} style={customStyles}  onClose={onClose} {...props}>
    <div className={`dialog sign-up _${type}`}>
      <div className="dialog__content" ref={polygonRef}>
        {children}
        <button onClick={onClose} className="btn-b _close-dialog"></button>
      </div>
    </div>
  </Modal>
}

const iconEnter = ({ target }) => {
  if (target.getAttribute("tOut") !== null) {
    clearTimeout(target.getAttribute("tOut"));
    target.removeAttribute("tOut");
  }
  target.classList.add("_active");
};

const iconLeave = ({ target }) => {
  if (target.getAttribute("tOut") === null) {
    target.setAttribute(
        "tOut",
        setTimeout(() => {
          target.classList.remove("_active");
        }, 2000),
    );
  }
};

const IconsBackground = () => {
  return <div className="icons">
    <span className="icons__item _lightning" data-depth="-0.1" />
    <span
        onMouseEnter={iconEnter}
        onMouseLeave={iconLeave}
        className="icons__item _red-heart"
        data-depth="-0.2"
    />
    <span
        onMouseEnter={iconEnter}
        onMouseLeave={iconLeave}
        className="icons__item _gold-star"
        data-depth="-0.2"
    />
    <span
        onMouseEnter={iconEnter}
        onMouseLeave={iconLeave}
        className="icons__item _green-kiss"
        data-depth="-0.4"
    />
    <span
        onMouseEnter={iconEnter}
        onMouseLeave={iconLeave}
        className="icons__item _red-kiss"
        data-depth="-0.3"
    />
    <span
        onMouseEnter={iconEnter}
        onMouseLeave={iconLeave}
        className="icons__item _violet-heart"
        data-depth="-0.2"
    />
    <span
        onMouseEnter={iconEnter}
        onMouseLeave={iconLeave}
        className="icons__item _gold-heart"
        data-depth="-0.2"
    />
    <span
        onMouseEnter={iconEnter}
        onMouseLeave={iconLeave}
        className="icons__item _violet-kiss"
        data-depth="-0.1"
    />
    <span
        onMouseEnter={iconEnter}
        onMouseLeave={iconLeave}
        className="icons__item _blue-heart"
        data-depth="-0.1"
    />
    <span
        onMouseEnter={iconEnter}
        onMouseLeave={iconLeave}
        className="icons__item _blue-star"
        data-depth="-0.1"
    />
    <span
        onMouseEnter={iconEnter}
        onMouseLeave={iconLeave}
        className="icons__item _green-heart"
        data-depth="-0.1"
    />
  </div>
}

const SignUp = () => {
  const [email, setEmail] = useState('')
  const [isShown, setIsShown] = useState(false)

  const [status, request] = useRequest(`/api/email/signUp`, `POST`, { email, game: 'video' })

  return (
    <>
      <h1 className="game-video__title sign-up__header" style={{zIndex: 1}}>Начнём!</h1>
      <main className="enter-screen _start">

        <div className="enter-screen__bg _enter">
          <div className="enter-screen__layer _back" />
          <div className="enter-screen__layer _front"></div>
        </div>

        <IconsBackground/>

        <div className="sign-up sign-up__container">
          <div className="sign-up__btns">
            <a href="/api/vk?to=video" className="btn-b _default _soc _vk">
              <AuthBtn /> <span>Войти через VKontakte</span>
            </a>
            <button
              className="btn-b _default _soc _email"
              onClick={() => setIsShown(true)}
            >
              <AuthBtn />
              <span>Войти через Email</span>
            </button>
          </div>
          <span className="sign-up__policy">
            Продолжая, вы&nbsp;подтверждаете, что вы&nbsp;прочитали
            и&nbsp;согласны с&nbsp;нашими Условиями,
            <a href="/game/privacy-policy.pdf" target="_blank">
              Политикой конфиденциальности
            </a>
          </span>
        </div>

        <Dialog
            isOpen={isShown}
            type={status.ok ? "confirm-email" : "input-email"}
            onClose={() => void setIsShown(false)}
        >
          { (() => {
            if(status.ok) {
              return <span>Письмо - подтверждение было отправлено на указанный email.
              Перейдите по ссылке в письме для начала игры.</span>
            }

            return <>
              <div className="dialog__field">
                <label htmlFor="">E-mail:</label>
                <input
                    type="text"
                    value={email}
                    onChange={event => void setEmail(event.target.value)}
                    disabled={status.loading}
                />
              </div>
              <button
                  className="btn-b _gold"
                  onClick={() => void request()}
                  disabled={status.loading}
              >
                <AuthBtn />
                <span>Войти</span>
              </button>
              {status.errorMessage && `Невалидный Email`}
            </>
          })()
          }
        </Dialog>
      </main>
    </>
  );
};

export default SignUp;
