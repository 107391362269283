import React from "react";

import PrizePeopleImg from 'assets/i/game-video-chat/prize_people.png'
import PrizeStickerLeftImg from 'assets/i/game-video-chat/prize_sticker.png'
import PrizeStickerRightImg from 'assets/i/game-video-chat/prize_sticker_2.png'
import SocialVkImg from 'assets/i/game-video-chat/social_vk.png'
import SocialFbImg from 'assets/i/game-video-chat/social_facebook.png'
import SocialTwImg from 'assets/i/game-video-chat/social_twitter.png'
import {safeym} from "../utils/safeym";

const successText = {
    title: 'Победа!',
    description: 'Да ты просто воплощение соблазнительной интуиции! Вся игра почти без косяков — видимо, ты не из тех, кто боится знакомства с родителями. Хватай Kracks и выходи на охоту — вечерний сериал ты будешь смотреть не только со своей правой рукой.',
    resultClass: 'game-video__result_success'
}
const failText = {
    title: 'Фейл!',
    description: 'Ну такое. Где-то интуиция подвела, где-то смекалка — все-таки тренировки с рукой не прокачивают навыки общения. Попробуй еще раз. Ну, или сходи за Kracks и хрусти в одного.',
    resultClass: 'game-video__result_fail'
}

export const GameOver = ({success}) => {
    const {title, description, resultClass} = success ? successText : failText
    return <>
        <h1 className="game-video__title">Результат</h1>
        <div className={`game-video__result ${resultClass}`}>
            <img className="game-video__result_image" src={PrizeStickerLeftImg}/>
            <div className="game-video__result_description">
                <h3 className="game-video__subtitle">{title}</h3>
                {description}
                {!success && <a className="game-video__try_again" href="/game-video-chat/">Попробовать ещё раз</a>}
            </div>
            <img className="game-video__result_image" src={PrizeStickerRightImg}/>
        </div>
        <div className="game-video__prize">
            <div className="game-video__prize_blocks">
                <img className="game-video__prize_people_img" src={PrizePeopleImg}/>
                {!success && <span className="game-video__prize_hint">Получи стикерпак за удачный пикап</span>}
                {success && <a className="game-video__prize_button"
                               onClick={() => safeym('reachGoal', 'stiker')}
                               href="https://tlgg.ru/addstickers/Kracks" target="_blank">Забрать
                    стикеры для Telegram</a>}
            </div>
            {success && <div className="game-video__prize_blocks">
                <p className="game-video__share_description">Поделиться результатом с друзьями:</p>

                <div className="game-video__share_buttons">
                    <a className="game-video__share_button"
                       onClick={() => safeym('reachGoal', 'VK-share')}
                       href="https://vk.com/share.php?url=https://www.kracksplay.com" target="_blank">
                        <img src={SocialVkImg}/>
                    </a>
                    <a className="game-video__share_button"
                       href="http://www.facebook.com/sharer/sharer.php?s=100&amp;p%5Btitle%5D=Поиграй с Kracks!&amp;p%5Bsummary%5D=&amp;p%5Burl%5D=https://www.kracksplay.com&amp;p%5Bimages%5D%5B0%5D=[IMAGE]"
                       target="_blank">
                        <img src={SocialFbImg}/>
                    </a>
                    <a className="game-video__share_button"
                       onClick={() => safeym('reachGoal', 'Twit-share')}
                       href="https://twitter.com/intent/tweet?original_referer=http%3A%2F%2Ffiddle.jshell.net%2F_display%2F&amp;text=Поиграй с Kracks!&amp;url=https://www.kracksplay.com"
                       target="_blank">
                        <img src={SocialTwImg}/>
                    </a>
                </div>
            </div>}
        </div>
    </>
}
