import React, {useEffect} from 'react';
import LogoImg from '../assets/i/logo.png';
import {GameStates} from "./GameStateMachine";
import {useRequest} from "../utils/use-request";
import SignUp from "./common/SignUp";

const GameVideoChat = () => {
    const [status, request] = useRequest(`/api/getMe`, `POST`)
    useEffect(() => void request(),[])
    return <div className="pre-game" style={{ minHeight: '85vh' }}>
        <div className="pre-game__bg">
            <a className="pre-game__logo" href="/"><img src={LogoImg} alt="Kracks"/></a>
            {!status.loading && <div className="game-video__content">
                {!status.ok && <SignUp/>}
                {status.ok && <GameStates/>}
            </div>}
        </div>
    </div>
}

export default GameVideoChat
