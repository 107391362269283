import {sceneCtx} from "./ctx";

const resultCounter = (acc, result) => acc + (result ? 1 : -1)

export const gameBoy = (() => {
    const ctx = sceneCtx('game_m/m', '1', 'Это будет фигня какая-то.', 'Это будет... просто ананас.', 'Привет! Меня нормально слышно?')
    ctx.sceneSplit(['2','3'], 'А можно отнести ананас в барбершоп?', 'Чем увлекаешься?')
    ctx.sceneSplit(['4','5'], 'Без шороха в кинотеатре', 'Без ананасов')
    ctx.alternative({
        videoName: '7e'
    }, (combo) => !combo[2] && combo.reduce(resultCounter,0) <= -3)
    ctx.sceneSplit(['6','7'], 'Не спать и не уставать', 'А есть суперсила «не работать»?')
    ctx.alternative({
        videoName: '9e', right: 'Вебинары... не, все-таки сериальчики', wrong: 'Саморазвитие'
    }, (combo) => combo[1] && !combo[3])
    ctx.sceneSplit(['8','9'], 'Вебинары... не, все-таки сериальчики', 'Саморазвитие')
    ctx.alternative({
        videoName: '11e'
    }, (combo) => !combo[4] && combo.reduce(resultCounter,0) <= -3)
    ctx.sceneSplit(['10','11'], 'Я скептически настроена', 'Звучит пикантно. Можно полную версию?')
    ctx.sceneSplit(['12','13'], 'Это фиаско', 'Мама не учила с едой не играть?')
    ctx.alternative({
        videoName: '15e', right: '«Только не в пиццу»', wrong: '«Ребристый друг лучше новых двух»'
    }, (combo) => !combo[0] && !combo[6])
    ctx.sceneSplit(['14','15'], '«Только не в пиццу»', '«Ребристый друг лучше новых двух»')
    ctx.alternative({
        videoName: '17e', right: 'Боб', wrong: 'Фрэнки',
    }, (combo) => !combo[1] && !combo[7])
    ctx.sceneSplit(['16','17'], 'Боб', 'Фрэнки')
    ctx.alternative({
        videoName: '19e'//, right: 'Смотрю, ты любишь усложнять', wrong: 'Кстати, я веган!'
    }, (combo) => !combo[8] && combo.reduce(resultCounter,0) === 0)
    ctx.sceneSplit(['18','19'], 'Смотрю, ты любишь усложнять', 'Кстати, я веган!')
    ctx.alternative({
        videoName: '21e'
    }, (combo) => !combo[9] && combo.reduce(resultCounter,0) >= 4)
    ctx.sceneSplit(['20','21'])
    ctx.end(['22','23'])
    return ctx.build()
})();
